import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";

class Thanks extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Thanks - Baton Rouge | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "We will be in touch within 1 business day. If you need immediate assistance please give us a call."
            }
          ]}
        />
        <div>
          <Img
            alt="Abstract Lighting"
            fluid={this.props.data.mainBg.childImageSharp.fluid}
            className="posts-bg"
          />
        </div>
        <div className="site-body page-content">
          <h1>Thanks for your submission</h1>
          <p>
          We will be in touch within 1 business day. If you need immediate assistance please give us a call at: <a className="no-break" href="tel:2252931086">225-293-1086</a>.
          </p>
          <Link className="button" to={"/posts/"}>Read Our Insurance Articles</Link>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default Thanks;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "abstract-bg-1.jpg" }) {
      ...mainBg
    }
  }
`;
